import React from 'react';
import bhasiniLogo from '../../assets/img/bhasiniLogo.png';
import startupIndiaLogo from '../../assets/img/startupIndia.png';
import googleStartupLogo from '../../assets/img/googleStartup.svg';
import neevcloudLogo from '../../assets/img/neevcloud.svg';
import peopleAiLogo from '../../assets/img/peopleAiLogo.svg';
import microsoftlogo from "../../assets/img/microsoft.svg"

const GoogleStartupLogos = () => {
  
  const logos = [
    {
      id: 1,
      image: googleStartupLogo,
      alt: "Google for Startups",
     
    },
    {
      id: 2,
      image: neevcloudLogo,
      alt: "NeevCloud logo",
      
    },
    {
      id: 3,
      image: peopleAiLogo,
      alt: "People AI logo",
 
    },
    {
      id: 4,
      image: bhasiniLogo,
      alt: "Bhasini",
   
    },
    {
      id: 5,
      image: startupIndiaLogo,
      logo: (
        <img 
          src={startupIndiaLogo} 
          alt="Startup India logo"
          style={{ 
            width: "auto", 
            height: "100px", 
            objectFit: "contain" 
          }}
        />
      ),
      alt: 'Startup India logo',
    },
    {
      id: 6,
      logo: (
        <img 
          src={microsoftlogo}
          alt="Microsoft logo"
          style={{ 
            width: "auto", 
            height: "100px", 
            objectFit: "contain" 
          }}
        />
      ),
      alt: 'Microsoft logo',
    }
  ];

  return (
      
    <div className="google-startups-wrapper">
      <h3 className="google-startups-title">
        Our Strategic Partners
      </h3>
      
      <div className="google-startups-logos">
        {logos.map((logo) => (
          <div 
            key={logo.id} 
            className="google-startups-logo-item"
          >

            {logo.logo ? (
              <div className="google-startups-logo-image">
                {logo.logo}
              </div>
            ) : (
              <img 
                src={logo.image} 
                alt={logo.alt} 
                className="google-startups-logo-image"
              />
            )}
            {logo.name && <p className="google-startups-logo-name">{logo.name}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoogleStartupLogos;