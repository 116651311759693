import React, { useEffect } from "react";
import CustomLink from "../components/CustomButton/CustomLink";
import EduHeader from "../components/Header/EduHeader";
import OuterFooter from "../components/Footer/OuterFooter";
import Faq from "../components/Faq/Faq";
import { initGA, logPageView, logEvent } from "../components/Analytics";
import GoogleStartupLogos from "../components/googleStartupLogos/GoogleStartupLogos";
import instant from "../assets/img/instant.png";
import grading from "../assets/img/grading.png";
import feedback from "../assets/img/feed.png";
import classInsights from "../assets/img/class.png";

const EducationPage = () => {

  const scrollToContactForm = (e, eventLabel = "Header - Book Pilot Program") => {
    e.preventDefault();
    const contactForm = document.querySelector('.get-in-touch-section');
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: 'smooth' });
    }
    logEvent("CTA", "Click", eventLabel);
  };

  useEffect(() => {
   
    initGA();
    logPageView();

   
    const checkAndAppendTag = (tagName, attributes) => {
      const existingTag = document.querySelector(
        `${tagName}[${Object.keys(attributes)[0]}="${
          Object.values(attributes)[0]
        }"]`
      );
      if (existingTag) {
        existingTag.remove();
      }

      // Create a new tag
      const tag = document.createElement(tagName);
      Object.entries(attributes).forEach(([key, value]) => {
        tag.setAttribute(key, value);
      });
      document.head.appendChild(tag);
    };

    // Update Title
    document.title =
      "AI-Powered Exam Grading and Student Performance Analysis | Chanakya AI";

    // Meta Description
    checkAndAppendTag("meta", {
      name: "description",
      content:
        "Transform education with Chanakya AI's automated exam grading and student performance analysis tools. Reduce grading time, provide insightful feedback, and track academic progress effortlessly.",
    });

    // Meta Keywords
    checkAndAppendTag("meta", {
      name: "keywords",
      content:
        "AI paper grading, student performance analysis, automated grading system, SWOT analysis in education, AI in education, exam analysis reports, Chanakya AI",
    });

    // Canonical URL
    checkAndAppendTag("link", {
      rel: "canonical",
      href: "https://aichanakya.in/edu",
    });

    // Open Graph Tags
    checkAndAppendTag("meta", {
      property: "og:title",
      content:
        "AI-Powered Exam Grading and Student Performance Analysis | Chanakya AI",
    });
    checkAndAppendTag("meta", {
      property: "og:description",
      content:
        "Accelerate grading and gain valuable insights into student performance with Chanakya AI's automated tools. Save time and focus on what matters most—education.",
    });
    checkAndAppendTag("meta", {
      property: "og:image",
      content: "https://aichanakya.in/edu/analyze.jpg",
    });
    checkAndAppendTag("meta", {
      property: "og:url",
      content: "https://aichanakya.in/edu",
    });
    checkAndAppendTag("meta", { property: "og:type", content: "website" });

    // Twitter Card Tags
    checkAndAppendTag("meta", {
      name: "twitter:card",
      content: "summary_large_image",
    });
    checkAndAppendTag("meta", {
      name: "twitter:title",
      content:
        "AI-Powered Exam Grading and Student Performance Analysis | Chanakya AI",
    });
    checkAndAppendTag("meta", {
      name: "twitter:description",
      content:
        "Automate exam grading and analyze student performance with ease. Discover faster, smarter, and more insightful ways to assess student learning.",
    });

    // Favicon
    checkAndAppendTag("link", {
      rel: "icon",
      href: "https://aichanakya.in/favicon.ico",
    });

    // Robots Meta
    checkAndAppendTag("meta", { name: "robots", content: "index, follow" });
  }, []);

  // const handlePlayPause = () => {
  //   setIsPlaying(!isPlaying);
  // };
  // const toggleMute = () => {
  //   videoRef.current.muted = !videoRef.current.muted;
  //   setIsMuted(videoRef.current.muted);
  // };




  // useEffect(() => {
  //   if (isPlaying) {
  //     videoRef.current.play();
  //   } else {
  //     videoRef.current.pause();
  //   }
  // }, [isPlaying]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const problemData = [
  //   {
  //     id: 0,
  //     title: "Time-Consuming Assessments",
  //     text: "Teachers spend 30+ hours per month on creating, checking, and analyzing tests, taking away valuable teaching time.",
  //     icon: <Icons.Clock size={24} />,
  //     images: [{ img: '' }]
  //   },
  //   {
  //     id: 1,
  //     title: "Inconsistent Evaluations",
  //     text: "Ensuring fairness and consistency across different batches becomes challenging with manual assessment methods.",
  //     icon: <Icons.Balance size={24} />,
  //     images: [{ img: "/consistency.png" }]
  //   },
  //   {
  //     id: 2,
  //     title: "Delayed Student Feedback",
  //     text: "Slow grading processes significantly impact student learning momentum and progress.",
  //     icon: <Icons.Feedback size={24} />,
  //     images: [{ img: "/feedback-delay.png" }]
  //   }
  // ];

  // const solutionData = [
  //   {
  //     id: 0,
  //     title: "AI-Powered Question Creation",
  //     text: "Generate comprehensive question papers based on syllabus requirements and past examination trends in minutes.",
  //     icon: <Icons.Brain size={24} />,
  //     images: [{ img: "/ai-generation.png" }]
  //   },
  //   {
  //     id: 1,
  //     title: "Instant Answer Evaluation",
  //     text: "Automatically check both handwritten and digital answers with consistent scoring criteria.",
  //     icon: <Icons.Check size={24} />,
  //     images: [{ img: "/instant-check.png" }]
  //   },
  //   {
  //     id: 2,
  //     title: "Smart Analytics & Insights",
  //     text: "Generate personalized performance insights for both students and teachers to enable data-driven improvements.",
  //     icon: <Icons.Chart size={24} />,
  //     images: [{ img: "/analytics.png" }]
  //   }
  // ];

  // const testimonialData = [
  //   {
  //     id: 1,
  //     quote: "Chanakya AI saved our teachers 20+ hours every week. Now they focus on actual teaching.",
  //     source: "Delhi Academy",
  //     metric: "20+ hours saved weekly"
  //   },
  //   {
  //     id: 2,
  //     quote: "Student scores improved by 30% in just 3 months thanks to AI-driven recommendations.",
  //     source: "ABC Coaching Institute",
  //     metric: "30% score improvement"
  //   },
  //   {
  //     id: 3,
  //     quote: "We automated 100% of our assessments – grading is no longer a headache.",
  //     source: "XYZ Ed-Tech",
  //     metric: "100% automation"
  //   }
  // ];

  // const audienceData = [
  //   {
  //     id: 1,
  //     title: "Schools & Coaching Institutes",
  //     icon: <Icons.BookOpen size={24} />,
  //     benefits: [
  //       "Automate assessments",
  //       "Improve results",
  //       "Save teacher time",
  //       "Standardize grading"
  //     ]
  //   },
  //   {
  //     id: 2,
  //     title: "Ed-Tech Platforms",
  //     icon: <Icons.Network size={24} />,
  //     benefits: [
  //       "Integrate AI-powered grading",
  //       "Get detailed insights",
  //       "Scale operations",
  //       "Enhance user experience"
  //     ]
  //   },
  //   {
  //     id: 3,
  //     title: "Universities & Education Institutes",
  //     icon: <Icons.Chart size={24} />,
  //     benefits: [
  //       "Scale exams efficiently",
  //       "Provide personalized feedback",
  //       "Track progress systematically",
  //       "Optimize resource allocation"
  //     ]
  //   }
  // ];

  // const getMetricIcon = (metric) => {
  //   if (metric.includes('%')) return '📈';
  //   if (metric.includes('min')) return '⚡';
  //   if (metric.toLowerCase().includes('score')) return '🎯';
  //   if (metric.toLowerCase().includes('students')) return '👥';
  //   if (metric.toLowerCase().includes('saved')) return '⏰';
  //   return '📊'; // default icon
  // };

  return (
    <div className="education-page">
      <EduHeader />
      <main className="education-page-main">
        <section className="banner-section">
          <div className="container">
            <div className="banner-wrapper">
              <div>
                <h1 className="banner-title">
                  Faster Exams,{" "}
                  <span className="orange-text">Sharper</span>{" "}
                  <span className="orange-text">Insights.</span>
                </h1>
                  <p className="banner-text">
                Automate Exams, Enhance Institutional Prestige, and Improve Student Outcomes with Chanakya AI
                </p>

                <CustomLink
                  linkText="Schedule a Demo"
                  LinkTo="#"
                  onClick={scrollToContactForm}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="problems-section">
          <div className="container">
            <h2 className="section-title">Is Your Institute Reaching Its True Potential?</h2>
            <p className="section-subtitle">
            Manual exams limit your institute growth, and delay student insights—impacting results, enrollment, and your institution's reputation.
            </p>
            <div className="problems-grid">
              {/* Card 1 */}
              <div className="problem-card">
                <div className="problem-stats">
                  <h3 className="problem-title">Exam Creation Bottlenecks</h3>
                  <div className="stat-item">
                    <span className="stat-icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"/>
                        <polyline points="12 6 12 12 16 14"/>
                      </svg>
                    </span>
                    <span className="stat-text"><span className="stat-highlight">10+ hrs/week</span> spent creating papers</span>
                  </div>
                  <div className="stat-item">
                    <span className="stat-icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M21 2v6h-6"/>
                        <path d="M3 12a9 9 0 0 1 15-6.7L21 8"/>
                        <path d="M3 22v-6h6"/>
                        <path d="M21 12a9 9 0 0 1-15 6.7L3 16"/>
                      </svg>
                    </span>
                    <span className="stat-text">Updating exam content takes <span className="stat-highlight">20+ hrs/semester</span></span>
                  </div>
                  <div className="stat-item">
                    <span className="stat-icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"/>
                        <line x1="16" y1="2" x2="16" y2="6"/>
                        <line x1="8" y1="2" x2="8" y2="6"/>
                        <line x1="3" y1="10" x2="21" y2="10"/>
                      </svg>
                    </span>
                    <span className="stat-text"><span className="stat-highlight">6-8 hrs</span> preparation per exam</span>
                  </div>
                </div>
              </div>

              {/* Card 2 */}
              <div className="problem-card">
                <div className="problem-stats">
                  <h3 className="problem-title">Inefficient Manual Grading</h3>
                  <div className="stat-item">
                    <span className="stat-icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M12 20h9"/>
                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"/>
                      </svg>
                    </span>
                    <span className="stat-text">Grading time: <span className="stat-highlight">10-15 mins/student</span></span>
                  </div>
                  <div className="stat-item">
                    <span className="stat-icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"/>
                        <polyline points="12 6 12 12 16 14"/>
                      </svg>
                    </span>
                    <span className="stat-text">Class of 40 students = <span className="stat-highlight">7-10 hrs</span> grading</span>
                  </div>
                  <div className="stat-item">
                    <span className="stat-icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <line x1="12" y1="1" x2="12" y2="23"/>
                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/>
                      </svg>
                    </span>
                    <span className="stat-text">Monthly grading costs: <span className="stat-highlight">₹50,000+</span></span>
                  </div>
                </div>
              </div>

              {/* Card 3 */}
              <div className="problem-card">
                <div className="problem-stats">
                  <h3 className="problem-title">Personalized Feedback? Rarely Possible.</h3>
                  <div className="stat-item">
                    <span className="stat-icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"/>
                      </svg>
                    </span>
                    <span className="stat-text">Only <span className="stat-highlight">10-15%</span> students get detailed feedback</span>
                  </div>
                  <div className="stat-item">
                    <span className="stat-icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"/>
                        <line x1="12" y1="8" x2="12" y2="12"/>
                        <line x1="12" y1="16" x2="12.01" y2="16"/>
                      </svg>
                    </span>
                    <span className="stat-text"><span className="stat-highlight">85%</span> unsure how to improve</span>
                  </div>
                  <div className="stat-item">
                    <span className="stat-icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                        <circle cx="12" cy="7" r="4"/>
                      </svg>
                    </span>
                    <span className="stat-text">Teachers lack time for personalized insights</span>
                  </div>
                </div>
              </div>

              {/* Card 4 */}
              <div className="problem-card">
                <div className="problem-stats">
                  <h3 className="problem-title">Lack of Actionable Insights</h3>
                  <div className="stat-item">
                    <span className="stat-icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"/>
                        <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"/>
                      </svg>
                    </span>
                    <span className="stat-text"><span className="stat-highlight">80%</span> struggle identifying learning gaps</span>
                  </div>
                  <div className="stat-item">
                    <span className="stat-icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <line x1="18" y1="20" x2="18" y2="10"/>
                        <line x1="12" y1="20" x2="12" y2="4"/>
                        <line x1="6" y1="20" x2="6" y2="14"/>
                      </svg>
                    </span>
                    <span className="stat-text"><span className="stat-highlight">70%</span> class insights lost due to no data</span>
                  </div>
                  <div className="stat-item">
                    <span className="stat-icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"/>
                        <polyline points="12 6 12 12 16 14"/>
                      </svg>
                    </span>
                    <span className="stat-text"><span className="stat-highlight">60%+</span> classroom time isn't optimized</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="feature-section">
          <div className="container">
            <h3 className="section-title">
              End-to-End Exam Automation, Tailored for CBSE
            </h3>
            <p className="section-subtitle">
            Chanakya AI strategically resolves your operational challenges by fully automating CBSE exam processes, inspired by Chanakya's timeless wisdom, designed to deliver measurable results.
            </p>
            <div className="everything-you-doNain">
              <div className="row">
                {[
                  {
                    id: 1,
                    wideCard: true,
                    textHeading: "Instant Question Paper Generation",
                    text: "Cut question-paper creation time from ",
                    highlightText1: " 8 hrs to minutes",
                    text2: ". AI-curated, ",
                    highlightText2: "CBSE-aligned",
                    text3: " content instantly.",
                    imgUrl: instant,
                  },
                  {
                    id: 2,
                    wideCard: false,
                    textHeading: "Rapid & Accurate Grading",
                    text: "Grading speed boosted by ",
                    highlightText1: "90%",
                    text2: " from hours to mere minutes, saving institutes ",
                    highlightText2: "₹50,000+/month",
                    text3: ".",
                    imgUrl: grading,
                  },
                  {
                    id: 3,
                    wideCard: false,
                    textHeading: " Personalized Student Feedback",
                    text: "Automated ",
                    highlightText1: "detailed feedback",
                    text2: " for ",
                    highlightText2: "100%",
                    text3: " of students solving the current gap from 10-15% to 100% personalized insights.",
                    imgUrl: feedback,
                  },
                  {
                    id: 4,
                    wideCard: true,
                    textHeading: " Actionable Class Insights",
                    text: "Gain precise analytics to ",
                    highlightText1: "pinpoint",
                    text2: " learning gaps, recovering ",
                    highlightText2: "70%",
                    text3: " of previously missed class insights.",
                    imgUrl: classInsights,
                  },
                ].map((item) => (
                  <div
                    key={item.id}
                    className={`${
                      item.wideCard 
                        ? "col-lg-12 col-xl-8 mx-auto feature-card-wide" 
                        : "col-lg-6 col-xl-4 feature-card-standard"
                    } mb-5`}
                  >
                    <div className="card everything-card">
                      <div className="card-body">
                        <div className="feature-image-wrapper">
                          <img src={item.imgUrl} alt={item.textHeading} />
                        </div>
                        <div className="feature-content">
                          <div className="feature-heading">
                            <h4 className="text-heading">{item.textHeading}</h4>
                          </div>
                          <div className="feature-text">
                            {item.text}<span className="highlight-text">{item.highlightText1}</span>{item.text2}
                            <span className="highlight-text">{item.highlightText2}</span>{item.text3}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        {/* <section className="how-it-works">
         <div className="container">
            <h3 className="section-title">How it Works</h3>
            
            <div className="works-card-main">
            
              <h4 className="section-divider">The Problems We Solve</h4>
              {problemData.map((items, index) => (
                <div key={index} className="works-cards-wrapper">
                  <div className="wrapper-step">
                    <span>{items.id + 1}</span>
                  </div>
                  <div className="content-wrapper">
                    <h3 className="work-title">
                      {items.title}
                      <div className="work-card-icon">{items.icon}</div>
                    </h3>
                    <p className="work-text">{items.text}</p>
                  </div>
                  <div className="img-main">
                    {items?.images?.map((item, index) => (
                      <img
                        loading="lazy"
                        key={index}
                        src={item.img}
                        className="img_1"
                        alt="side-image"
                        title={items.tooltip_text}
                      />
                    ))}
                  </div>
                </div>
              ))}
              

              <h4 className="section-divider">Our Solution</h4>
              {solutionData.map((items, index) => (
                <div key={index} className="works-cards-wrapper">
                  <div className="wrapper-step">
                    <span>{items.id + 1}</span>
                  </div>
                  <div className="content-wrapper">
                    <h3 className="work-title">
                      {items.title}
                      <div className="work-card-icon">{items.icon}</div>
                    </h3>
                    <p className="work-text">{items.text}</p>
                  </div>
                  <div className="img-main">
                    {items?.images?.map((item, index) => (
                      <img
                        loading="lazy"
                        key={index}
                        src={item.img}
                        className="img_1"
                        alt="side-image"
                        title={items.tooltip_text}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section> */}
        <section className="institutional-impact-section">
          <div className="institutional-impact-container">
            <div className="institutional-impact-card">
              <div className="institutional-impact-content card-body">
                <div className="institutional-impact-header text-center">
                  <h5 className="institutional-impact-badge leading-mark">Real Institutional Impact</h5>
                  <h3 className="institutional-impact-title">Saves Time, Reduces Cost, Improves Result</h3>
                  <p className="institutional-impact-subtitle section-subtitle">
                    Chanakya AI directly translates operational efficiency into measurable institutional impact, ensuring you stay competitive and prestigious
                  </p>
                </div>
                <div className="institutional-impact-metrics-grid">
                  <div className="impact-metric-card">
                    <div className="impact-metric-icon-wrapper">
                      <svg className="impact-metric-icon" viewBox="0 0 24 24" width="32" height="32" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M12 3v18M3 12h18M12 8l4 4M12 16l-4-4"/>
                      </svg>
                    </div>
                    <div className="impact-metric-content">
                      <h4 className="impact-metric-title">Scale Enrollment Rapidly</h4>
                      <p className="impact-metric-description">
                        Automate assessments to reclaim <span className="highlight-text">300+ teaching hours per year</span>, enabling growth without extra staffing.
                      </p>
                    </div>
                    <div className="impact-metric-stats-row">
                      <span className="impact-metric-value">300+</span>
                      <span className="impact-metric-label">Hours Saved Annually</span>
                    </div>
                  </div>

                  <div className="impact-metric-card">
                    <div className="impact-metric-icon-wrapper">
                      <svg className="impact-metric-icon" viewBox="0 0 24 24" width="32" height="32" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M6 9v11a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V9"/>
                        <path d="M3 5h18l-1.447-2.894A2 2 0 0 0 17.763 1H6.237a2 2 0 0 0-1.79 1.106L3 5z"/>
                        <path d="M12 12l4 4m-4-4v8m-4-4l4-4"/>
                      </svg>
                    </div>
                    <div className="impact-metric-content">
                      <h4 className="impact-metric-title">Instant Prestige Boost</h4>
                      <p className="impact-metric-description">
                        Immediate result delivery and comprehensive feedback set your institute apart, elevating your competitive position.
                      </p>
                    </div>
                    <div className="impact-metric-stats-row">
                      <span className="impact-metric-value">90%</span>
                      <span className="impact-metric-label">Faster Results Delivery</span>
                    </div>
                  </div>

                  <div className="impact-metric-card">
                    <div className="impact-metric-icon-wrapper">
                      <svg className="impact-metric-icon" viewBox="0 0 24 24" width="32" height="32" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M3 3v18h18"/>
                        <path d="M18.4 8l-6-6-6 6"/>
                        <path d="M6.4 16l6-6 6 6"/>
                      </svg>
                    </div>
                    <div className="impact-metric-content">
                      <h4 className="impact-metric-title">🎓 Better Student Outcomes</h4>
                      <p className="impact-metric-description">
                        Personalized, AI-generated insights ensure every student clearly understands areas for improvement, increasing engagement by up to <span className="highlight-text">30%</span>.
                      </p>
                    </div>
                    <div className="impact-metric-stats-row">
                      <span className="impact-metric-value">30%</span>
                      <span className="impact-metric-label">Improved Engagement</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="google-startups-section" data-aos="fade-up">
          <div className="container">
            <GoogleStartupLogos />  
          </div>
        </section>

        {/* <section className="target-audience">
          <div className="container">
            <div className="section-header">
              <h2 className="section-title">Who is This For?</h2>
              <p className="section-subtitle">Tailored solutions for every educational institution</p>
            </div>
            <div className="audience-grid">
              {audienceData.map((item) => (
                <div key={item.id} className="audience-card">
                  <div className="audience-title">
                    <div className="audience-icon">{item.icon}</div>
                    {item.title}
                  </div>
                  <ul className="audience-list">
                    {item.benefits.map((benefit, index) => (
                      <li key={index} className="audience-item">
                        <Icons.RoundCheck size={16} className="audience-check" />
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section> */}
        <section className="pilot-program-section">
          <div className="container">
            <div className="card pilot-program-card">
              <div className="card-body pilot-program-body">
                <div className="pilot-program-header">
                  <h3 className="pilot-program-title">Exclusive Pilot Program for Forward-Thinking Institutes</h3>
                </div>
                
                <p className="pilot-program-description">
                  We are currently selecting a limited number of institutes for our exclusive Chanakya AI Pilot Program. Participating institutes receive:
                </p>
                
                <div className="pilot-benefits-list">
                  {[
                    {
                      icon: (
                        <svg viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
                        <polyline points="7.5 4.21 12 6.81 16.5 4.21" />
                        <polyline points="7.5 19.79 7.5 14.6 3 12" />
                        <polyline points="21 12 16.5 14.6 16.5 19.79" />
                        <polyline points="3.27 6.96 12 12.01 20.73 6.96" />
                        <line x1="12" y1="22.08" x2="12" y2="12" />
                      </svg>
                      ),
                      title: "Personalized onboarding & dedicated support",
                      text: ""
                    },
                    {
                      icon: (
                        <svg viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z"/>
                          <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z"/>
                          <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0"/>
                          <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5"/>
                        </svg>
                      ),
                      title: "Early access to cutting-edge AI assessment technology",
                      text: ""
                    },
                    {
                      icon: (
                        <svg viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M3 3v18h18"/>
                          <path d="m19 9-5 5-4-4-3 3"/>
                          <circle cx="7" cy="8" r="1"/>
                          <circle cx="10" cy="12" r="1"/>
                          <circle cx="14" cy="14" r="1"/>
                          <circle cx="19" cy="9" r="1"/>
                        </svg>
                      ),
                      title: "Detailed impact reporting & analytics",
                      text: ""
                    }
                  ].map((benefit, index) => (
                    <div key={index} className="pilot-benefit-item">
                      <div className="benefit-icon-wrapper">
                        {benefit.icon}
                      </div>
                      <div className="benefit-content">
                        <h4 className="benefit-title">{benefit.title}</h4>
                        {benefit.text && <p className="benefit-text">{benefit.text}</p>}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="pilot-program-cta">
                  <p className="pilot-program-cta-text">Secure your institute's competitive advantage today.</p>
                  <CustomLink
                    linkText="Reserve Your Pilot Spot"
                    customClasses="pilot-program-cta-button"
                    LinkTo="#"
                    onClick={(e) => scrollToContactForm(e, "Pilot Program - Reserve Spot")}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="why-choose-section">
          <div className="container">
            <div className="card why-choose-card">
              <div className="card-body why-choose-body">
                <div className="why-choose-header">
                  <h5 className="why-choose-label">Why Chanakya?</h5>
                  <h3 className="why-choose-title">Ancient Wisdom Meets Modern AI</h3>
                  <p className="why-choose-subtitle">
                    Our name pays homage to Chanakya, India's legendary strategist, scholar, and teacher. Just as Chanakya strategically guided kings and shaped empires, Chanakya AI strategically guides educational institutes, helping you effortlessly navigate operational challenges and achieve remarkable growth.
                  </p>
                  <p className="why-choose-subtitle" style={{marginTop: "20px"}}>
                    We combine timeless wisdom with powerful AI technology to give your institution a strategic edge—today and tomorrow.
                  </p>
                </div>


                
                {/* <div className="why-choose-list">
                  {[
                    "Strategic Guidance: Like our namesake, we help institutions navigate challenges and achieve growth",
                    "Educational Excellence: Continuing Chanakya's legacy of transformative education",
                    "Proven Results: 99%+ accuracy in AI-based assessment",
                    "Scalable Solution: Handle 1000+ students effortlessly",
                    "Time Efficiency: 10x faster than manual grading"
                  ].map((item, index) => (
                    <div key={index} className="why-choose-item">
                      <Icons.RoundCheck size={16} className="why-choose-check" />
                      <span>{item}</span>
                    </div>
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <section className="get-in-touch-section">
          <div className="container">
            <div className="card get-in-touch-card">
              <div className="card-body get-in-touch-body">
                <div className="get-in-touch-header">
                  <h5 className="get-in-touch-label leading-mark">Get in Touch</h5>
                  <h3 className="get-in-touch-title">Have Questions? Let's Talk!</h3>
                  <p className="get-in-touch-subtitle">
                    Fill out the form below and we'll get back to you shortly
                  </p>
                </div>
                
                <form 
                  id="contactForm"
                  className="get-in-touch-form" 
                  onSubmit={(e) => {
                    e.preventDefault();
                    
                    const form = e.target;
                    const formData = new FormData(form);
                    
                    const data = {
                      'entry.1984153101': formData.get('entry.1984153101'), 
                      'entry.1027524614': formData.get('entry.1027524614'), 
                      'entry.62502021': formData.get('entry.62502021'), 
                      'entry.867612995': formData.get('entry.867612995'),
                      'entry.208877176': formData.get('entry.208877176'),
                      'fvv': '1',
                      'pageHistory': '0',
                      'fbzx': '8635124972700245440'
                    };
               
                    const formBody = Object.keys(data)
                      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                      .join('&');
               
                    fetch('https://docs.google.com/forms/u/1/d/e/1FAIpQLScyV38ZE6ShrnxL1VFF4pnu7XyEgM73QPVRJ7k2CjoQJm-dcQ/formResponse', {
                      method: 'POST',
                      mode: 'no-cors',
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                      },
                      body: formBody
                    })
                    .then(() => {
                      document.getElementById('contactForm').style.display = 'none';
                      document.getElementById('thankYouMessage').style.display = 'block';
                      logEvent("Form", "Submit", "Contact Form");
                    })
                    .catch(error => {
                      console.error('Error:', error);
                      document.getElementById('contactForm').style.display = 'none';
                      document.getElementById('thankYouMessage').style.display = 'block';
                    });
                  }}
                >
                  <div className="form-row">
                    <div className="form-group">
                      <input 
                        type="text" 
                        className="form-control" 
                        placeholder="Your Name*"
                        name="entry.1027524614"
                        required 
                      />
                    </div>
                    <div className="form-group">
                      <input 
                        type="email" 
                        className="form-control" 
                        placeholder="Email Address*"
                        name="entry.1984153101"
                        required 
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <input 
                      type="text"
                      className="form-control" 
                      placeholder="Institution ( Optional )"
                      name="entry.62502021"
                    />
                  </div>
                  <div className="form-group">
                    <input 
                      type="tel" 
                      className="form-control" 
                      placeholder="Phone Number*"
                      name="entry.867612995"
                      required 
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="Your Message*"
                      name="entry.208877176"
                      rows="4"
                      required
                    ></textarea>
                  </div>
                  <button 
                    type="submit" 
                    className="submit-button"
                  >
                    Send Message
                  </button>
                </form>
                
       
                <div 
                  id="thankYouMessage" 
                  style={{ display: 'none' }}
                  className="thank-you-message"
                >
                  <h4>Thank you for getting in touch!</h4>
                  <p>We've received your message and will contact you shortly to discuss how Chanakya AI can transform your institute's assessment process.</p>
                  <button 
                    className="submit-button"
                    onClick={() => {
                      document.getElementById('contactForm').style.display = 'block';
                      document.getElementById('thankYouMessage').style.display = 'none';
                      document.getElementById('contactForm').reset();
                    }}
                  >
                    Submit Another Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faq-section">
          <Faq 
            faqQuestion={[
              {
                id: 1,
                question: "How does Chanakya AI's assessment system work?",
                answere: "Chanakya AI uses advanced artificial intelligence to automatically create exams, check handwritten and digital answers, and provide detailed performance analytics. Our system can understand and evaluate student responses with 99% accuracy, saving teachers hours of grading time while providing consistent, unbiased assessments."
              },
              {
                id: 2,
                question: "How much time can teachers save with Chanakya AI?",
                answere: "Teachers typically save 30% of their workload with our platform. What used to take hours of manual grading can now be completed in minutes, allowing educators to focus more on teaching and less on administrative tasks."
              },
              {
                id: 3,
                question: "Is Chanakya AI suitable for all subjects and grade levels?",
                answere: "Yes, our platform is designed to work across all subjects and grade levels. Whether you're teaching mathematics, sciences, humanities, or languages, Chanakya AI can handle various question types and assessment formats."
              },
              {
                id: 4,
                question: "How accurate is the AI grading system?",
                answere: "Our AI grading system achieves over 99% accuracy when compared to expert human graders. The system has been trained on millions of student responses and continuously improves through machine learning."
              },
              {
                id: 5,
                question: "How can I get started with Chanakya AI?",
                answere: "Getting started is easy! Simply schedule a demo with our team, and we'll walk you through the platform and set up a pilot program tailored to your institution's needs. We provide comprehensive onboarding and support throughout the implementation process."
              }
            ]}
          />
        </section>

    

        <section className="powerUp-section">
          <div className="container">
            <div className="tag-main">
              <h3 className="section-tag">Power Up Your Learning</h3>
            </div>
            <p className="powerUp-text">
              Give your students quicker, better, more insightful feedback on
              their tests
            </p>
            <div className="accessBox-main">
              <CustomLink
                linkText="Request Your Pilot Demo"
                customClasses="access-btn"
                LinkTo="#"
                onClick={scrollToContactForm}
              />
            </div>
          </div>
        </section>



      
      </main>
      <OuterFooter />
      <button 
        className="floating-call-button"
        onClick={(e) => scrollToContactForm(e, "Floating Call Button")}
        aria-label="Contact Us"
      >
        <svg 
          viewBox="0 0 24 24" 
          width="24" 
          height="24" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        >
          <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
        </svg>
      </button>
    </div>
  );
};

export default EducationPage;

