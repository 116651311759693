import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import DownArrow from "../../assets/img/down_arrow.svg";
import DoubleUpArrow from "../../assets/img/CaretDoubleUp.svg";
import ChanakyaLogo from "../../assets/img/chanakyaLogo.svg";

const Header = ({
  scrollToSection,
  mobNav,
  setMobNav,
  setMobNavbar,
  mobNavbar,
  rightBtnText,
  rightBtnLink,
  handleDownloadAppButtonClick,
}) => {
  const location = useLocation();
  const [deviceType, setDeviceType] = useState("");

  const handleMobileNav = () => {
    if (location?.pathname === "/privacy" || location?.pathname === "/tos") {
      setMobNavbar(!mobNavbar);
    } else {
      setMobNav(!mobNav);
    }
  };

  const getDeviceType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    if (/window phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/Win/i.test(userAgent)) {
      return "Windows";
    }

    return "unknown";
  };

  useEffect(() => {
    const device = getDeviceType();
    setDeviceType(device);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <header className="header">
        <nav className="nav navbar-nav">
          <div className="header-logo">
            <Link to="/" className="logo" onClick={scrollToTop}>
              <img src={ChanakyaLogo} alt="" />
            </Link>
          </div>
          <div className="navbar-links">
            <ul className="nav-list">
              {location?.pathname === "/privacy" ||
              location?.pathname === "/tos" ? (
                ""
              ) : (
                <>
                  {location?.pathname === "/chat" && (
                    <li className="nav-items">
                      <Link to="/" className="nav-link">
                        Home
                      </Link>
                    </li>
                  )}
                  <li className="nav-items">
                    <Link to="/about-us" className="nav-link" onClick={handleMobileNav}>
                      About Us
                    </Link>
                  </li>
                  <li className="nav-items dropdown">
                    <span className="nav-link">
                      Products
                    </span>
                    <div className="dropdown-content">
                      <Link to="/" className="dropdown-item">
                        <div className="dropdown-item-content">
                          <h4>Chanakya Assessments</h4>
                          <p>AI-powered assessments for better learning outcomes</p>
                        </div>
                      </Link>
                      <Link to="/chat" className="dropdown-item">
                        <div className="dropdown-item-content">
                          <h4>Chanakya AI Bot</h4>
                          <p>Your personal AI tutor for instant help</p>
                        </div>
                      </Link>
                    </div>
                  </li>
                  <li className="nav-items">
                    <button
                      onClick={() => scrollToSection("explore")}
                      className="nav-link"
                    >
                      Explore AI
                    </button>
                  </li>
                  <li className="nav-items">
                    <button
                      onClick={() => scrollToSection("pricing")}
                      className="nav-link"
                    >
                      Pricing
                    </button>
                  </li>
                  <li className="nav-items">
                    <button
                      onClick={() => scrollToSection("faq")}
                      className="nav-link"
                    >
                      FAQ'S
                    </button>
                  </li>
                  {location?.pathname !== "/chat" && (
                    <>
                      <li className="nav-items">
                        <Link to="/edu-business" className="nav-link">
                          For Institutes
                        </Link>
                      </li>
                      <li className="nav-items">
                        <Link to="https://exams.aichanakya.in/" className="nav-link">
                          Sign in
                        </Link>
                      </li>
                    </>
                  )}
                  {location?.pathname !== "/chat" && (
                    <li>
                      <Link to="/chat" className="nav-link">
                        Chanakya AI Bot
                      </Link>
                    </li>
                  )}
                </>
              )}
              <li className="nav-items">
                {rightBtnLink ? (
                  <a
                    href={rightBtnLink}
                    target="_blank"
                    rel="noreferrer"
                    className="btn download-btn"
                  >
                    {rightBtnText}
                  </a>
                ) : (
                  <a
                    href={
                      deviceType === "Windows"
                        ? "https://chat.aichanakya.in/"
                        : deviceType === "Android"
                        ? "https://play.google.com/store/apps/details?id=tech.neurobridge.chanakya"
                        : deviceType === "iOS"
                        ? "https://apps.apple.com/us/app/chanakya-ai/id6504797681"
                        : "/"
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="btn download-btn"
                    onClick={handleDownloadAppButtonClick}
                  >
                    {rightBtnText}
                  </a>
                )}
              </li>
            </ul>
            {mobNav ? (
              <button className="close-nav" onClick={handleMobileNav}>
                <img src={DoubleUpArrow} alt="icon" />
              </button>
            ) : (
              <button className="toggle-btn" onClick={handleMobileNav}>
                <span></span>
              </button>
            )}
          </div>
        </nav>
      </header>
      <div
        className={`mobileNav-wrapper ${mobNav || mobNavbar ? "active" : ""}`}
      >
        <ul className="mobileNav-list">
          {location?.pathname === "/privacy" ||
          location?.pathname === "/tos" ? (
            ""
          ) : (
            <>
              {location?.pathname !== "/" && (
                <li className="mobileNav-item">
                  <Link to="/" className="mob-nav-btn">
                    Home
                    <img src={DownArrow} alt="icon" />
                  </Link>
                </li>
              )}
              <li className="mobileNav-item">
                <Link to="/about-us" className="mob-nav-btn" onClick={handleMobileNav}>
                  About Us
                  <img src={DownArrow} alt="icon" />
                </Link>
              </li>
              <li className="mobileNav-item dropdown">
                <span className="mob-nav-btn">
                  Products
                </span>
                <div className="dropdown-content">
                  <Link to="/" className="dropdown-item" onClick={handleMobileNav}>
                    Chanakya Assessments
                  </Link>
                  <Link to="/chat" className="dropdown-item" onClick={handleMobileNav}>
                    Chanakya AI Bot
                  </Link>
                </div>
              </li>
              <li className="mobileNav-item">
                <button
                  type="button"
                  className="mob-nav-btn"
                  onClick={() => {
                    handleMobileNav();
                    setTimeout(() => scrollToSection("explore"), 300);
                  }}
                >
                  Explore AI
                  <img src={DownArrow} alt="icon" />
                </button>
              </li>
              <li className="mobileNav-item">
                <button
                  type="button"
                  className="mob-nav-btn"
                  onClick={() => {
                    handleMobileNav();
                    setTimeout(() => scrollToSection("pricing"), 300);
                  }}
                >
                  Pricing
                  <img src={DownArrow} alt="icon" />
                </button>
              </li>
              <li className="mobileNav-item">
                <button
                  type="button"
                  className="mob-nav-btn"
                  onClick={() => {
                    handleMobileNav();
                    setTimeout(() => scrollToSection("faq"), 300);
                  }}
                >
                  FAQs
                  <img src={DownArrow} alt="icon" />
                </button>
              </li>
              {location?.pathname !== "/chat" && (
                <li className="mobileNav-item">
                  <Link to="/edu-business" className="mob-nav-btn" onClick={handleMobileNav}>
                    For Institutes
                    <img src={DownArrow} alt="icon" />
                  </Link>
                </li>
              )}
              {location?.pathname !== "/chat" && (
                <li className="mobileNav-item">
                  <Link to="/chat" className="mob-nav-btn">
                    Chanakya AI Bot
                    <img src={DownArrow} alt="icon" />
                  </Link>
                </li>
              )}
              {location?.pathname !== "/chat" && (
                <li className="mobileNav-item">
                  <a
                    href="https://exams.aichanakya.in/"
                    className="mob-nav-btn"
                    // onClick={handleMobileNav}
                  >
                    Sign In
                    <img src={DownArrow} alt="icon" />
                  </a>
                </li>
              )}
            </>
          )}
          <li className="mobileNav-item">
            {rightBtnLink ? (
              <a
                href={rightBtnLink}
                target="_blank"
                rel="noreferrer"
                className="mob-download-btn"
              >
                {rightBtnText}
              </a>
            ) : (
              <a
                href={
                  deviceType === "Windows"
                    ? "https://chat.aichanakya.in/"
                    : deviceType === "Android"
                    ? "https://play.google.com/store/apps/details?id=tech.neurobridge.chanakya"
                    : deviceType === "iOS"
                    ? "https://apps.apple.com/us/app/chanakya-ai/id6504797681"
                    : "/"
                }
                target="_blank"
                rel="noreferrer"
                className="mob-download-btn"
                onClick={handleDownloadAppButtonClick}
              >
                {rightBtnText}
              </a>
            )}
          </li>
        </ul>
      </div>
      <div
        className={`navback-drop ${mobNav || mobNavbar ? "show" : ""}`}
        onClick={handleMobileNav}
      ></div>
    </>
  );
};

export default Header;