import React, { useEffect, useRef, useState } from "react";
import "../style.css";
import Banner from "../../components/Banner/Banner";
import CustomButton from "../../components/CustomButton/CustomButton";
import Faq from "../../components/Faq/Faq";
import CountUp from "react-countup";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import blackTick from "../../assets/img/black_circle_tick.svg";
import blueTick from "../../assets/img/blue_circle_tick.svg";
import Chats from "../../assets/img/chatsI.png";
import Interaction from "../../assets/img/interaction.png";
import Phone_button from "../../assets/img/phone_btns.png";
import Language from "../../assets/img/language.png";
import GoogleStartupLogos from "../../components/googleStartupLogos/GoogleStartupLogos";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import {
  // initGA,
  logPageView,
  logEvent,
  // logException,
} from "../../components/Analytics";

gsap.registerPlugin(ScrollTrigger);

const ChatPage = () => {
  const [mobNav, setMobNav] = useState(false);
  const [deviceType, setDeviceType] = useState("");
  const [isAnnual, setIsAnnual] = useState(false);

  const aboutRef = useRef(null);
  const exploreRef = useRef(null);
  const pricingRef = useRef(null);
  const faqRef = useRef(null);

  const textRef = useRef(null);

  useEffect(() => {
    const split = new SplitType(textRef.current, { types: "words" });

    gsap.set(split.words, {
      color: "rgba(0, 0, 0, 0.52)",
    });

    gsap.to(split.words, {
      color: "rgba(0, 0, 0)",
      textShadow: "none",
      stagger: 0.05,
      duration: 1,
      scrollTrigger: {
        trigger: textRef.current,
        start: "top 80%",
        end: "top 20%",
        scrub: true,
      },
    });

    return () => {
      split.revert();
    };
  }, []);

  const toggleSubscription = () => {
    setIsAnnual((prev) => !prev);
  };

  const scrollToSection = (section, native) => {
    const HEADER_HEIGHT = 100;
    let element;
    if (native) {
      if (section === "about" && aboutRef.current) {
        element = aboutRef.current;
        setMobNav(!mobNav);
      } else if (section === "explore" && exploreRef.current) {
        element = exploreRef.current;
        setMobNav(!mobNav);
      } else if (section === "pricing" && pricingRef.current) {
        element = pricingRef.current;
        setMobNav(!mobNav);
      } else if (section === "faq" && faqRef.current) {
        element = faqRef.current;
        setMobNav(!mobNav);
      }
    } else {
      if (section === "about" && aboutRef.current) {
        element = aboutRef.current;
      } else if (section === "explore" && exploreRef.current) {
        element = exploreRef.current;
      } else if (section === "pricing" && pricingRef.current) {
        element = pricingRef.current;
      } else if (section === "faq" && faqRef.current) {
        element = faqRef.current;
      }
    }
    if (element) {
      window.scrollTo({
        top: element.offsetTop - HEADER_HEIGHT,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // initGA();
    logPageView();
  }, []);

  const getDeviceType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    if (/Macintosh/.test(userAgent) && "ontouchend" in document) {
      // iPads running iOS 13+ identify as "Macintosh"
      return "iOS (iPad)";
    }
    if (/Windows Phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/Win/i.test(userAgent)) {
      return "Windows";
    }
    if (/Mac/i.test(userAgent)) {
      return "Mac";
    }
    if (/Linux/i.test(userAgent)) {
      return "Linux";
    }

    return "unknown";
  };

  const handleTryChanakyaButtonClick = () => {
    logEvent({
      category: "User",
      action: "Try Chanakya Button Clicked",
      label: "Try Chanakya - It's FREE",
    });

    console.log("GAAAAA");
  };

  const handleDownloadAppButtonClick = () => {
    logEvent({
      category: "User",
      action: "Download App Button Clicked",
      label: "Download App",
    });

    const url = (() => {
      switch (deviceType) {
        case "Windows":
          return "https://chat.aichanakya.in/";
        case "Mac":
          return "https://chat.aichanakya.in/";
        case "Android":
          return "https://play.google.com/store/apps/details?id=tech.neurobridge.chanakya";
        case "iOS":
          return "https://apps.apple.com/us/app/chanakya-ai/id6504797681";
        default:
          return "/";
      }
    })();

    window.location.href = url;
  };

  useEffect(() => {
    const device = getDeviceType();
    setDeviceType(device);
    document.title = "Chanakya AI";
  }, []);

  const revolutionData = [
    {
      id: 0,
      title: "12",
      prefix: "",
      suffix: "+",
      text: "Language Supported",
    },
    {
      id: 1,
      title: "1",
      prefix: "",
      suffix: "Million+",
      text: "Conversations Handled",
    },
    {
      id: 2,
      title: "1 ",
      prefix: "<",
      suffix: "secs",
      text: "Response Time",
    },
  ];
  const subscriptionData = [
    {
      id: 0,
      title: "Chanakya",
      subtitle: "₹0 month",
      text: "Perfect For New AI Enthusiasts",
      free: true,
      btnText: "Start Now",
      keyPointsTitle: "Unlock the Basics:",
      keyPoints: [
        {
          id: 0,
          keyText: "Keep on top of your schedule and daily tasks",
          img: blackTick,
        },
        {
          id: 1,
          keyText: "Experience AI in 5+ Indic languages.",
          img: blackTick,
        },
        {
          id: 2,
          keyText: "Try out our awesome voice capabilities.",
          img: blackTick,
        },
        {
          id: 3,
          keyText: "Enjoy basic memory for ongoing conversations.",
          img: blackTick,
        },
      ],
    },
    {
      id: 1,
      title: "Chanakya",
      annualPrice: {
        original: "₹7200",
        discounted: "₹5040",
      },
      monthlyPrice: {
        original: "₹600",
        discounted: "₹99",
      },

      text: "Perfect For Power Users & Professionals",
      free: false,
      discountBadge: isAnnual ? "30% OFF" : "83% OFF",
      btnText: "Start Now",
      keyPointsTitle: "Elevate Your Experience:",
      keyPoints: [
        {
          id: 0,
          keyText: "5x more messages",
          img: blueTick,
        },
        {
          id: 1,
          keyText: "AI in 12+ Indic languages",
          img: blueTick,
        },
        {
          id: 2,
          keyText: "Real-time web search",
          img: blueTick,
        },
        {
          id: 3,
          keyText: "Advanced AI models",
          img: blueTick,
        },
        {
          id: 4,
          keyText: "Extended chat memory",
          img: blueTick,
        },
        {
          id: 5,
          keyText: "Full voice interaction (coming soon)",
          img: blueTick,
        },
      ],
    },
  ];
  const everythingData = [
    {
      id: 0,
      wideCard: true,
      textHeading: "Multilingual and Culturally Personalised Responses",
      text: "Chanakya supports multiple Indian languages like Hindi, Tamil, Telugu, Bengali, and more, including Hinglish. It provides culturally nuanced responses, making it accessible and relevant to diverse users across India.",
      imgUrl: Language,
    },
    {
      id: 1,
      wideCard: false,
      textHeading: "Up-to-Date Knowledge and Real-Time Data Integration",
      text: "Chanakya uses the latest data and Google search to provide accurate, real-time information, ensuring users get precise answers to their questions.",
      imgUrl: Chats,
    },
    {
      id: 2,
      wideCard: false,
      textHeading: "Personalised Interactions",
      text: "Chanakya personalised responses by remembering user interactions and preferences, creating a more engaging and modified experience that boosts user satisfaction and loyalty.",
      imgUrl: Interaction,
    },
    {
      id: 3,
      wideCard: true,
      textHeading: "Voice-First Interaction",
      text: "Chanakya's voice-first approach enables natural, spoken discussions, making it accessible for users facing literacy challenges by allowing them to communicate through speaking and listening.",
      imgUrl: Phone_button,
    },
  ];
  const faqQuestion = [
    {
      id: 0,
      question: "What is Chanakya?",
      answere:
        "Chanakya, developed by Neurobridge, is an advanced AI language model that engages in natural conversations, generates human-like text, and offers comprehensive information on various topics. Its sophisticated algorithms understand context and deliver nuanced responses.",
    },
    {
      id: 1,
      question: "How does Chanakya operate?",
      answere:
        "Chanakya operates using a large-scale language model based on deep learning techniques. Here's a concise breakdown of how it works:",
      list: [
        {
          heading: "Natural Language Understanding:",
          content:
            "Chanakya can comprehend and interpret the meaning behind user queries and messages, understanding context and intent.",
        },
        {
          heading: "Text Generation:",
          content:
            "It generates responses that are contextually relevant and linguistically coherent, mimicking human conversation patterns.",
        },
        {
          heading: "Conversation Continuity:",
          content:
            "Chanakya can maintain the context of a conversation over multiple turns, remembering previous messages to provide appropriate follow-up responses.",
        },
        {
          heading: "Personalization:",
          content:
            "While not inherently storing user data between sessions, Chanakya can be fine-tuned or adapted based on ongoing interactions to better meet user needs and preferences. ",
        },
      ],
    },
    {
      id: 2,
      question: "Which languages does Chanakya support?",
      answere:
        "Hindi, English, Hinglish, Marathi, Punjabi, Tamil, Telugu, Bengali, Kannada, Gujarati, Malayalam and many more.",
    },
    {
      id: 3,
      question: "Can Chanakya provide real-time updates?",
      answere:
        "Chanakya is seamlessly integrated with web search, ensuring real-time updates and the latest information at your fingertips.",
    },
    {
      id: 4,
      question: "Can Chanakya learn and improve over time? ",
      answere:
        "Yes, Chanakya can be fine-tuned and updated with new data to improve its performance and accuracy. It learns from user interactions to provide more relevant and helpful responses.",
    },
  ];

  const handleSubscriptionButtonClick = (buttonLabel, id) => {
    logEvent({
      category: "User",
      action: "50% Off Chanakya Pro Subscription Button Clicked",
      label: buttonLabel,
    });

    if (id === 1 || id === 2) {
      const url = (() => {
        switch (deviceType) {
          case "Windows":
            return "https://chat.aichanakya.in/";
          case "Mac":
            return "https://chat.aichanakya.in/";
          case "Android":
            return "https://play.google.com/store/apps/details?id=tech.neurobridge.chanakya";
          case "iOS":
            return "https://apps.apple.com/us/app/chanakya-ai/id6504797681";
          default:
            return "/";
        }
      })();

      window.location.href = url;
    }
  };

  return (
    <div className="chat-page">
      <Header
        scrollToSection={scrollToSection}
        setMobNav={setMobNav}
        mobNav={mobNav}
        rightBtnText="Download App"
        handleDownloadAppButtonClick={handleDownloadAppButtonClick}
      />
      <main className="landingPage-wrapper">
        <Banner
          bannerTagline="🎯 Transform Teaching & Learning with AI"
          bannerTitle="India's Own Multilingual AI Companion"
          tryAiChat
          availableOn
          bannerText="Enhance learning with AI-powered assessments and <br />
          personalized assistance for students and educators"
          deviceType={deviceType}
          handleTryChanakyaButtonClick={handleTryChanakyaButtonClick}
          handleDownloadAppButtonClick={handleDownloadAppButtonClick}
          appQrCode
          isChatRoute={true}
        />
        <section
          className="banner-bottom"
          ref={aboutRef}
          id="about"
          data-aos="fade-up"
        >
          <div className="container">
            <div className="card bottom-card">
              <div className="card-body bCard-body">
                <h5 className="leading-mark">India's AI Revolution</h5>
                <div ref={textRef}>
                  <p className="card-text">
                    <span className="text-heading">
                      Chanakya, developed by Neurobridge,
                    </span>
                    &nbsp; is an AI Generative language model designed to
                    generate human-like text. It excels in answering questions,
                    providing information, and engaging in conversations on a
                    wide range of topics.
                  </p>
                  <p className="card-text mb5">
                    What sets Chanakya apart is its ability to learn and improve
                    over time, ensuring more accurate and helpful responses.
                    Plus, it supports multilingual interactions for a seamless
                    experience.
                  </p>
                </div>
                <div className="row">
                  {revolutionData?.map((item) => (
                    <div className="col-md-4">
                      <div className="card number-card">
                        <div className="card-body">
                          <h3 className="card-title">
                            {item.prefix}
                            <CountUp
                              start={item?.title === "12" ? 0 : 20}
                              end={item?.title}
                              duration={2}
                              enableScrollSpy
                              scrollSpyOnce
                            />
                            {` ${item.suffix}`}
                          </h3>
                          <p className="card-text">{item?.text}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="everything-you-do-sec"
          ref={exploreRef}
          id="explore"
          data-aos="fade-up"
        >
          <div className="container">
            <div className="everything-you-doNain">
              <h3 className="landing-title">AI for everything you do</h3>
              <p className="landing-text">
                Boost productivity, spark creativity, and stay connected with
                Chanakya—an Ai companion that intelligently adapts to your
                lifestyle, supporting you wherever you work.
              </p>
              <div className="row">
                {everythingData?.map((item) => (
                  <div
                    className={`${
                      item.wideCard ? "col-lg-8" : "col-lg-4"
                    } mb-5`}
                  >
                    <div className="card everything-card">
                      <div className="card-body">
                        <div className="text-center mb-4">
                          <img src={item?.imgUrl} alt="imageUrl" />
                        </div>
                        <p className="everything-text">
                          <span>{item?.textHeading}: </span>
                          {item?.text}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="google-startups-section" data-aos="fade-up">
          <div className="container">
            <GoogleStartupLogos />
          </div>
        </section>
        <section
          className="explore-section"
          ref={pricingRef}
          id="pricing"
          data-aos="fade-up"
        >
          <div className="container">
            <div className="explore-cardNain">
              <h5 className="leading-mark">We are Neurobridge</h5>
              <h3 className="landing-title">
                Explore Chanakya's personalized journey for you.
              </h3>
              <p className="landing-text">
                Get started for free—or take your Copilot experience to the next
                level.
              </p>
              <div className="subscription-main">
                <div className="row flex-lg-nowrap gap-5">
                  {subscriptionData?.map((item) => (
                    <div
                      key={item.id}
                      className={`col-lg-6 card subscription-card ${
                        item.free ? "" : "active"
                      }`}
                    >
                      <div className="card-body position-relative">
                        {/* Toggle button */}
                        <div className="discounted-content">
                          {item.discountBadge && (
                            <span className="discount-badge">
                              {item.discountBadge}
                            </span>
                          )}
                          {!item.free && (
                            <div>
                              <label className="form-switch">
                                <input
                                  type="checkbox"
                                  checked={isAnnual}
                                  onChange={toggleSubscription}
                                />
                                <i></i>
                                <span>{isAnnual ? "Annually" : "Monthly"}</span>
                              </label>
                            </div>
                          )}
                        </div>
                        <div className="desktop-toggle">
                          <h4 className="subs-title">
                            {item?.title}
                            <span className={item?.free ? "free" : "pro"}>
                              {item.free ? "Free" : "Pro"}
                            </span>
                            {"   "}
                            {item.discountBadge && (
                              <span className="discount-badge ms-2">
                                {item.discountBadge}
                              </span>
                            )}
                          </h4>
                          {!item.free && (
                            <div>
                              <label className="form-switch">
                                <input
                                  type="checkbox"
                                  checked={isAnnual}
                                  onChange={toggleSubscription}
                                />
                                <i></i>
                                <span>{isAnnual ? "Annually" : "Monthly"}</span>
                              </label>
                            </div>
                          )}
                        </div>

                        <h6 className="subs-subtitle">
                          {item.free ? (
                            item.subtitle
                          ) : isAnnual && item.annualPrice ? (
                            <>
                              <span className="original-price">
                                {item.annualPrice.original}
                              </span>
                              <span className="discounted-price">
                                {" "}
                                {item.annualPrice.discounted}
                                <small className="additional-info d-block discounted-price-text">
                                  (For the first year{" "}
                                  {item.annualPrice.discounted}, then{" "}
                                  {item.annualPrice.original} per year)
                                </small>
                              </span>{" "}
                            </>
                          ) : (
                            <>
                              <span className="original-price">
                                {item.monthlyPrice.original}
                              </span>
                              <span className="discounted-price">
                                {" "}
                                {item.monthlyPrice.discounted}
                                <small className="additional-info d-block discounted-price-text">
                                  (For the first month{" "}
                                  {item.monthlyPrice.discounted}, then{" "}
                                  {item.monthlyPrice.original} per month)
                                </small>
                              </span>{" "}
                            </>
                          )}
                        </h6>

                        <p className="subs-text">{item?.text}</p>
                        <div className="btn-box">
                          <CustomButton
                            btnText={item.btnText}
                            customClasses="mb-4"
                            onClick={() =>
                              handleSubscriptionButtonClick(item.title, item.id)
                            }
                          />
                        </div>
                        <h6 className="subs-subtitle mb-3">
                          {item?.keyPointsTitle}
                        </h6>
                        <div className="key-pointsMain ps-4">
                          {item?.keyPoints?.map((keyPoint, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-start"
                            >
                              <img src={keyPoint?.img} alt="icon" />
                              <p className="keyPoints-text">
                                {keyPoint?.keyText}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="faq-section"
          ref={faqRef}
          id="faq"
          data-aos="fade-up"
        >
          <Faq faqQuestion={faqQuestion} />
        </section>
        <Footer
          btnText="Try Chanakya - It's FREE"
          btnLink="https://chat.aichanakya.in/"
          handleTryChanakyaButtonClick={handleTryChanakyaButtonClick}
          mobileSection={true}
        />
      </main>
    </div>
  );
};

export default ChatPage;
