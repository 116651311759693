import React from "react";
import "./style.css";
import tiltArrow from "../../assets/img/tiltArrow.svg";

const CustomLink = ({ LinkTo, customClasses, linkText, onClick, showArrow = true }) => {
  return (
    <div className="btn-wrapper">
      <a
        href={LinkTo}
        className={`btn custom-btn ${customClasses}`}
        onClick={onClick}
      >
        {linkText}
        {showArrow && <img src={tiltArrow} alt="logo" />}
      </a>
    </div>
  );
};

export default CustomLink;
