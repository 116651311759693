import React, { useState } from "react";
import ChanakyaLogo from "../../assets/img/chanakyaLogo.svg";
import DownArrow from "../../assets/img/down_arrow.svg";
import DoubleUpArrow from "../../assets/img/CaretDoubleUp.svg";
import { Link, useLocation } from "react-router-dom";

const EduHeader = () => {
  const [mobNav, setMobNav] = useState(false);
  const location = useLocation();
  const isHowItWorksPage = location.pathname === '/how-it-works';
  
  const handleMobileNav = () => {
    setMobNav(!mobNav);
  };

  return (
    <>
      <header className="header">
        <nav className="nav navbar-nav">
          <div className="header-logo">
            <a href="/" className="logo">
              <img src={ChanakyaLogo} alt="" />
            </a>
          </div>
          <div className="navbar-links">
            <ul className="nav-list">
              <li className="nav-items">
                <a href="/" className="nav-link">
                  Home
                </a>
              </li>
              <li className="nav-items dropdown">
                <span className="nav-link">
                  Products
                </span>
                <div className="dropdown-content">
                  <Link to="/" className="dropdown-item">
                    <div className="dropdown-item-content">
                      <h4>Chanakya Assessments</h4>
                      <p>AI-powered assessments for better learning outcomes</p>
                    </div>
                  </Link>
                  <Link to="/chat" className="dropdown-item">
                    <div className="dropdown-item-content">
                      <h4>Chanakya AI Bot</h4>
                      <p>Your personal AI tutor for instant help</p>
                    </div>
                  </Link>
                </div>
              </li>
              {!isHowItWorksPage && (
                <li className="nav-items">
                  <Link to="/how-it-works" className="nav-link">
                    How Chanakya AI Works
                  </Link>
                </li>
              )}
              <li className="nav-items">
                <Link to="/about-us" className="nav-link">
                  About Us
                </Link>
              </li>
              <li className="nav-items">
                <a
                  href="https://calendly.com/atharv-gyem/discovery-call"
                  rel="noreferrer"
                  className="btn download-btn"
                >
                  Get Early Access
                </a>
              </li>
            </ul>
            {mobNav ? (
              <button className="close-nav" onClick={handleMobileNav}>
                <img src={DoubleUpArrow} alt="icon" />
              </button>
            ) : (
              <button className="toggle-btn" onClick={handleMobileNav}>
                <span></span>
              </button>
            )}
          </div>
        </nav>
      </header>
      <div className={`mobileNav-wrapper ${mobNav ? "active" : ""}`}>
        <ul className="mobileNav-list">
          <li className="mobileNav-item">
            <Link to="/" className="mob-nav-btn" onClick={handleMobileNav}>
              Home
              <img src={DownArrow} alt="icon" />
            </Link>
          </li>
          <li className="mobileNav-item dropdown">
            <span className="mob-nav-btn">
              Products
            </span>
            <div className="dropdown-content">
              <Link to="/" className="dropdown-item" onClick={handleMobileNav}>
                Chanakya Assessments
              </Link>
              <Link to="/chat" className="dropdown-item" onClick={handleMobileNav}>
                Chanakya AI Bot
              </Link>
            </div>
          </li>
          {!isHowItWorksPage && (
            <li className="mobileNav-item">
              <Link to="/how-it-works" className="mob-nav-btn" onClick={handleMobileNav}>
                How Chanakya AI Works
                <img src={DownArrow} alt="icon" />
              </Link>
            </li>
          )}
           <li className="mobileNav-item">
            <Link to="/about-us" className="mob-nav-btn" onClick={handleMobileNav}>
              About Us
              <img src={DownArrow} alt="icon" />
            </Link>
          </li>
          <li className="mobileNav-item">
            <a
              href="https://calendly.com/atharv-gyem/discovery-call"
              target="_blank"
              rel="noreferrer"
              className="mob-download-btn"
            >
              Get Early Access
            </a>
          </li>
        </ul>
      </div>
      <div
        className={`navback-drop ${mobNav ? "show" : ""}`}
        onClick={handleMobileNav}
      ></div>
    </>
  );
};

export default EduHeader;