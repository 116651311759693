import React, { useRef, useEffect, useState } from "react";
import EduHeader from "../components/Header/EduHeader";
import { InlineMath, BlockMath } from "react-katex";
import html2canvas from "html2canvas";
import "katex/dist/katex.min.css";
import testImage1 from "../assets/img/testImag1.png";
import testImage2 from "../assets/img/testImag2.png";
import testImage3 from "../assets/img/testImag3.png";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../components/Analytics";

export function ArcadeEmbed() {
  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "calc(51.66666666666667% + 41px)",
        height: 0,
        width: "100%",
      }}
    >
      <iframe
        src="https://demo.arcade.software/OLHrjI3ObSPeLGGIxFvN?embed&embed_mobile=inline&embed_desktop=inline&show_copy_link=true"
        title="Effortlessly Craft and Analyze CBSE Assessments with Chanakya AI"
        frameBorder="0"
        loading="lazy"
        allowFullScreen
        allow="clipboard-write"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          colorScheme: "light",
        }}
      />
    </div>
  );
}

function OCRComparison() {
  const [sliderPositions, setSliderPositions] = useState({
    example1: 40,
    example2: 40,
    example3: 40,
  });
  const [canvasImages, setCanvasImages] = useState({
    example1: null,
    example2: null,
    example3: null,
  });
  const [isDragging, setIsDragging] = useState({
    example1: false,
    example2: false,
    example3: false,
  });

  const containerRefs = {
    example1: useRef(null),
    example2: useRef(null),
    example3: useRef(null),
  };

  const mathContentRefs = {
    example1: useRef(null),
    example2: useRef(null),
    example3: useRef(null),
  };

  const ocrTextExamples = {
    example1: {
      math: [
        String.raw`3 \angle y - 2 \angle x = 14 \theta^{\circ}`,
        String.raw`
\begin{aligned}
& \frac{2 H 5}{32 y} + 22 x \\
= & 3(1 \\
= & 3\left(\frac{1}{3}(360-160)\right) \\
= & 3\left(\frac{1}{2} \times \frac{108}{200}\right) \\
= & 32 \theta - 160 \\
= & 14 \theta^{\circ} \\
= & R . H . S
\end{aligned}
`,
        String.raw`
\begin{aligned}
& = 3(1 \\
& = 3\left(\frac{1}{2}(36 \theta - 160)\right) - 2\left(\frac{1}{2}(160)\right) \\
& (\cdots \text{ Angle }
\end{aligned}
`,
      ],
      text: [
        "Q.E.D",
        "(.. Angle subtended by an antre at the is dis .-n double the L subtended -by it on the remaining part.",
      ],
    },
    example2: {
      math: [
        String.raw`
\begin{aligned}
& \left(\begin{array}{l}
\text { From a table of integrals } \\
\int \frac{d x}{\sqrt{a^2-x^2}}=\sin ^{-1} \frac{x}{a} \cdot \text{or} -\cos ^{-1} \frac{x}{a} \\
\frac{1}{a} \int \frac{d v}{\sqrt{1-v^{2}}}=\frac{1}{a} \sin ^{-1}(v) \text { or } \frac{-1}{a} \cos ^{-1}(v) \\
\end{array}\right]=x+k_3 \\
& {\left[\begin{array}{c}
\sin ^{-1}(v) \\
-\cos ^{-1}(v)
\end{array}\right]=a x+k_4 \text { as } v=\frac{x}{a}}
\end{aligned}
`,
      ],
      text: [
        "Therefore, the solution to the integral is expressed in terms of inverse trigonometric functions.",
        "This demonstrates the relationship between the original integral and its standard form.",
      ],
    },
    example3: {
      math: [],
      text: [
        "Some people believe that the purpose of education is to make individuals useful to society while others think it is that education should help people pursue personal ambitions.",
        "Discuss both views and give your own opinion.",
        "When it comes to the purpose of education, opinions are divided as some see its primary role as preparing individuals to be useful to society while others believe it should primarily serve people's personal ambitions.",
      ],
    },
  };

  useEffect(() => {
    const examples = ["example1", "example2", "example3"];

    examples.forEach((example) => {
      if (mathContentRefs[example].current) {
        setTimeout(() => {
          html2canvas(mathContentRefs[example].current, {
            backgroundColor: "white",
            scale: 2,
            logging: true,
            useCORS: true,
            allowTaint: true,
            onclone: function (clonedDoc) {
              const clonedContent = clonedDoc.querySelector(
                `.ai-works-math-content-${example}`
              );
              if (clonedContent) {
                clonedContent.style.visibility = "visible";
                clonedContent.style.position = "static";
                clonedContent.style.left = "0";
                clonedContent.style.top = "0";
                clonedContent.style.width = "800px";
                clonedContent.style.padding = "30px";
                clonedContent.style.background = "white";
              }
            },
          })
            .then((canvas) => {
              setCanvasImages((prev) => ({
                ...prev,
                [example]: canvas.toDataURL("image/png"),
              }));
            })
            .catch((err) => {
              console.error(`Error generating canvas for ${example}:`, err);
            });
        }, 1500);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMouseDown = (e, example) => {
    e.preventDefault();
    setIsDragging((prev) => ({
      ...prev,
      [example]: true,
    }));
  };

  // const handleMouseUp = (example) => {
  //   setIsDragging((prev) => ({
  //     ...prev,
  //     [example]: false,
  //   }));
  // };

  const handleMouseMove = (e, example) => {
    if (!isDragging[example] || !containerRefs[example].current) return;

    const containerRect =
      containerRefs[example].current.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const offsetX = e.clientX - containerRect.left;

    let newPosition = (offsetX / containerWidth) * 100;
    newPosition = Math.max(0, Math.min(100, newPosition));

    setSliderPositions((prev) => ({
      ...prev,
      [example]: newPosition,
    }));
  };

  // Add and remove event listeners
  useEffect(() => {
    const examples = ["example1", "example2", "example3"];

    const handleGlobalMouseUp = () => {
      setIsDragging({
        example1: false,
        example2: false,
        example3: false,
      });
    };

    const handleGlobalMouseMove = (e) => {
      examples.forEach((example) => {
        if (isDragging[example]) {
          handleMouseMove(e, example);
        }
      });
    };

    document.addEventListener("mousemove", handleGlobalMouseMove);
    document.addEventListener("mouseup", handleGlobalMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleGlobalMouseMove);
      document.removeEventListener("mouseup", handleGlobalMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const getExampleImage = (example) => {
    switch (example) {
      case "example1":
        return testImage1;
      case "example2":
        return testImage2;
      case "example3":
        return testImage3;
      default:
        return testImage1;
    }
  };

  // Function to render a single example
  const renderExample = (example, index, title, description) => {
    return (
      <div className="ai-works-example-item">
        <div className="ai-works-example-header">
          <div className="ai-works-example-number">{index}</div>
          <div className="ai-works-example-name">{title}</div>
        </div>

        <div className="ai-works-ocr-slider-container">
          <div
            ref={containerRefs[example]}
            className="ai-works-ocr-slider-wrapper"
          >
            {/* Document on the left */}
            <div
              className="ai-works-ocr-document"
              style={{ width: `${sliderPositions[example]}%` }}
            >
              <img
                src={getExampleImage(example)}
                alt="Original Document"
                className="ai-works-ocr-image"
              />
            </div>

            {/* OCR Result on the right */}
            <div
              className="ai-works-ocr-result"
              style={{
                left: `${sliderPositions[example]}%`,
                width: `${100 - sliderPositions[example]}%`,
              }}
            >
              {canvasImages[example] && (
                <img
                  src={canvasImages[example]}
                  alt="OCR Result"
                  className="ai-works-ocr-canvas"
                />
              )}
            </div>

            {/* Updated slider handle */}
            <div
              className="ai-works-ocr-slider"
              style={{ left: `${sliderPositions[example]}%` }}
              onMouseDown={(e) => handleMouseDown(e, example)}
            >
              <div className="ai-works-ocr-slider-handle">
                <span className="ai-works-ocr-slider-arrow">↔</span>
              </div>
            </div>
          </div>

          <div className="ai-works-ocr-labels">
            <div
              className={`ai-works-ocr-label ${
                sliderPositions[example] < 50 ? "active" : ""
              }`}
            >
              Document
            </div>
            <div
              className={`ai-works-ocr-label ${
                sliderPositions[example] >= 50 ? "active" : ""
              }`}
            >
              OCR Result
            </div>
          </div>
        </div>

        <p className="ai-works-example-description">{description}</p>
      </div>
    );
  };

  return (
    <div className="ai-works-ocr-demo">
      <div className="ai-works-examples-sequence">
        {renderExample("example1", 1, "Mathematical Proof Recognition")}

        {renderExample("example2", 2, "Advanced Mathematical Notation")}

        {renderExample("example3", 3, "Comprehensive Text Analysis")}
      </div>

      {Object.keys(ocrTextExamples).map((example) => (
        <div
          key={example}
          ref={mathContentRefs[example]}
          className={`ai-works-math-content ai-works-math-content-${example}`}
          style={{
            position: "absolute",
            left: "-9999px",
            top: 0,
            width: "800px",
            background: "white",
            padding: "30px",
            visibility: "hidden",
          }}
        >
          {ocrTextExamples[example].math &&
            ocrTextExamples[example].math.map((mathExp, index) => (
              <BlockMath key={`math-${index}`} math={mathExp} />
            ))}

          {ocrTextExamples[example].text.map((text, index) => (
            <p
              key={`text-${index}`}
              style={{ marginBottom: "1rem", lineHeight: "1.6" }}
            >
              {text.includes("L") ? (
                <>
                  {text.split("L")[0]}
                  <InlineMath math="L" />
                  {text.split("L")[1]}
                </>
              ) : (
                text
              )}
            </p>
          ))}
        </div>
      ))}
    </div>
  );
}

const ChanakyaAi = () => {
  const [showOcr] = useState(false);
  const mathRef = useRef(null);
  const canvasRef = useRef(null);
  const navigate = useNavigate();

  const handleGetStartedClick = (e) => {
    e.preventDefault();

    logEvent("CTA", "Click", "ChanakyaAI - Get Started Free");

    navigate("/");

    setTimeout(() => {
      const contactForm = document.querySelector(".get-in-touch-section");
      if (contactForm) {
        contactForm.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  useEffect(() => {
    if (mathRef.current && canvasRef.current) {
      html2canvas(mathRef.current).then((canvas) => {
        const ctx = canvasRef.current.getContext("2d");
        canvasRef.current.width = mathRef.current.offsetWidth;
        canvasRef.current.height = mathRef.current.offsetHeight;
        ctx.drawImage(canvas, 0, 0);
      });
    }
  }, [showOcr]);

  return (
    <div className="education-page">
      <EduHeader />

      <div className="ai-works-main">
        {/* Banner Section */}
        <section className="ai-works-banner-section">
          <div className="container">
            <div className="ai-works-banner-wrapper">
              <div className="ai-works-banner-content">
                <h1 className="ai-works-banner-title">
                  Revolutionize{" "}
                  <span className="ai-works-orange-text">Assessment</span>{" "}
                  Creation with{" "}
                  <span className="ai-works-caveat-text">Chanakya AI</span>
                </h1>
                <p className="ai-works-banner-text">
                  Effortlessly create, analyze, and optimize CBSE assessments
                  with our AI-powered platform designed for educators.
                </p>
                <div className="ai-works-btn-box">
                  <div className="ai-works-btn-wrapper">
                    <button
                      className="ai-works-custom-btn"
                      onClick={handleGetStartedClick}
                    >
                      Get Started Free
                    </button>
                  </div>
                </div>
              </div>
              <div className="ai-works-banner-right">
                <div className="ai-works-banner-card">
                  <h3 className="ai-works-card-title">
                    Chanakya AI helps you:
                  </h3>
                  <ul className="ai-works-card-list">
                    <li className="ai-works-card-list-item">
                      <span className="ai-works-check-icon"></span> Create
                      CBSE-aligned assessments in seconds
                    </li>
                    <li className="ai-works-card-list-item">
                      <span className="ai-works-check-icon"></span> Generate
                      detailed analytics and insights
                    </li>
                    <li className="ai-works-card-list-item">
                      <span className="ai-works-check-icon"></span> Save hours
                      of manual assessment creation
                    </li>
                    <li className="ai-works-card-list-item">
                      <span className="ai-works-check-icon"></span> Improve
                      student learning outcomes
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Demo Section */}
        <section className="ai-works-demo-section">
          <div className="container">
            <div className="ai-works-section-header">
              <span className="ai-works-leading-mark">INTERACTIVE DEMO</span>
              <h2 className="ai-works-section-title">
                See Chanakya AI in Action
              </h2>
              <p className="ai-works-section-subtitle">
                Watch how easily you can create and analyze assessments with our
                intuitive platform
              </p>
            </div>
            <div className="ai-works-macbook-showcase">
              <div className="ai-works-macbook-frame">
                <div className="ai-works-macbook-lid">
                  <div className="ai-works-macbook-display">
                    <div className="ai-works-macbook-top-bar">
                      <div className="ai-works-macbook-camera"></div>
                    </div>
                    <div className="ai-works-macbook-screen">
                      <ArcadeEmbed />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ai-works-how-it-works">
          <div className="container">
            <div className="ai-works-section-header">
              <span className="ai-works-leading-mark">HOW IT WORKS</span>
              <h2 className="ai-works-section-title">
                Chanakya AI Simplifies Assessment
              </h2>
              <p className="ai-works-section-subtitle">
                Our platform streamlines the entire assessment process from
                creation to analysis
              </p>
            </div>

            <OCRComparison />
          </div>
        </section>

        <section className="ai-works-target-audience">
          <div className="container">
            <div className="ai-works-section-header">
              <span className="ai-works-leading-mark">WHO IT'S FOR</span>
              <h2 className="ai-works-section-title">
                Perfect for Educators at All Levels
              </h2>
              <p className="ai-works-section-subtitle">
                Chanakya AI is designed to support various educational roles
              </p>
            </div>
            <div className="ai-works-audience-grid">
              <div className="ai-works-audience-card">
                <div className="ai-works-audience-title">
                  <div className="ai-works-audience-icon ai-works-teacher-icon"></div>
                  Teachers
                </div>
                <ul className="ai-works-audience-list">
                  <li className="ai-works-audience-item">
                    <span className="ai-works-audience-check"></span>
                    Save time on assessment creation
                  </li>
                  <li className="ai-works-audience-item">
                    <span className="ai-works-audience-check"></span>
                    Focus more on teaching
                  </li>
                  <li className="ai-works-audience-item">
                    <span className="ai-works-audience-check"></span>
                    Create varied question types
                  </li>
                </ul>
              </div>

              <div className="ai-works-audience-card">
                <div className="ai-works-audience-title">
                  <div className="ai-works-audience-icon ai-works-users-icon"></div>
                  School Administrators
                </div>
                <ul className="ai-works-audience-list">
                  <li className="ai-works-audience-item">
                    <span className="ai-works-audience-check"></span>
                    Standardize assessment quality
                  </li>
                  <li className="ai-works-audience-item">
                    <span className="ai-works-audience-check"></span>
                    Track school-wide performance
                  </li>
                  <li className="ai-works-audience-item">
                    <span className="ai-works-audience-check"></span>
                    Improve educational outcomes
                  </li>
                </ul>
              </div>

              <div className="ai-works-audience-card">
                <div className="ai-works-audience-title">
                  <div className="ai-works-audience-icon ai-works-chart-icon"></div>
                  Educational Institutions
                </div>
                <ul className="ai-works-audience-list">
                  <li className="ai-works-audience-item">
                    <span className="ai-works-audience-check"></span>
                    Ensure CBSE compliance
                  </li>
                  <li className="ai-works-audience-item">
                    <span className="ai-works-audience-check"></span>
                    Analyze institutional trends
                  </li>
                  <li className="ai-works-audience-item">
                    <span className="ai-works-audience-check"></span>
                    Enhance teaching methodologies
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="ai-works-powerUp-section">
          <div className="container">
            <div className="ai-works-tag-main">
              <span className="ai-works-section-tag">
                READY TO TRANSFORM YOUR ASSESSMENTS?
              </span>
            </div>
            <h2 className="ai-works-powerUp-text">
              Join thousands of educators using Chanakya AI to create better
              assessments in less time
            </h2>
            <div className="ai-works-accessBox-main">
              <a
                href="get started"
                className="ai-works-access-text"
                onClick={handleGetStartedClick}
              >
                Get Started Free
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ChanakyaAi;
