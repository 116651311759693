import React, { useEffect, useState } from "react";
import "./style.css";
import EduHeader from "../../components/Header/EduHeader";
import historyImg from "../../assets/img/chanakyaAi.webp";
import director2 from "../../assets/img/atharvImg.jpg";
import garvitimg from "../../assets/img/garvitImg.jpeg";
import { Icons } from "../../components/icons";
import OuterFooter from "../../components/Footer/OuterFooter";
import LeftDrawer from "../../components/LeftDrawer";
import SantoshImg from "../../assets/img/santosh.jpeg"
import harrisImg from "../../assets/img/harrisImg.jpg"
import suyashImg from "../../assets/img/suyashImg.jpeg"
import yashImg from "../../assets/img/yash.jpeg"
const AboutPage = () => {
  const [tab, setTab] = useState("directors");
  const [showDrawer, setShowDrawer] = useState(false);
  const [directorData, setDirectorData] = useState({
    img: "",
    name: "",
    designation: "",
    about: "",
    email: "",
    linkedin: "",
  });

  const visionData = [
    {
      id: 0,
      title: "Our Vision",
      text: "To democratize AI, enabling its adoption across education and knowledge systems, freeing people from monotonous processes and fostering innovation and impactful learning experiences.",
    },
    {
      id: 1,
      title: "Our Mission",
      text: "To create affordable, efficient, and scalable AI tools that eliminate repetitive tasks, simplify information systems, and make education smarter, faster, and more accessible to everyone.",
    },
  ];

  const valuesData = [
    {
      id: 0,
      btnText: "Democratization of AI",
      text: " Making cutting-edge AI tools accessible to everyone, irrespective of their scale or resources.",
    },
    {
      id: 1,
      btnText: "Efficiency and Innovation",
      text: "Eliminating inefficiencies to enable smarter learning and decision-making.",
    },
    {
      id: 2,
      btnText: "Empowering the Masses",
      text: " Helping people leverage AI to unlock new opportunities and thrive in a fast-paced, knowledge-driven world.",
    },
  ];

  const myTeam = [
    {
      listOfDirectors: [
        {
          id: 0,
          name: "Atharv",
          designation: "COO/Co-Founder",
          img: director2,
          email: "atharv@aichanakya.in",
          about:
            "With a profound understanding of industry trends and unwavering commitment, he spearheads our strategic direction, driving innovation and growth.",
          linkedin: "https://www.linkedin.com/in/atharvgarg/",
        },
        {
          id: 1,
          name: "Garvit",
          designation: "CEO/Co-Founder",
          img: garvitimg,
          email: "garvit@aichanakya.in",
          about:
            "With a profound understanding of industry trends and unwavering commitment, he spearheads our strategic direction, driving innovation and growth.",
          linkedin: "https://www.linkedin.com/in/garvit-k/",
        },
      ],
    },
    {
      listOfTeam: [
        {
          id: 0,
          title: "Suyash Vashishtha",
          text: "Lead Software engineer",
          img: suyashImg,
        },
        {
          id: 1,
          title: "Harris Fazal",
          text: "Frontend Developer",
          img: harrisImg,
        },
        {
          id: 2,
          title: "Santosh Kumar",
          text: "Python Developer",
          img: SantoshImg,
        },
        {
          id: 3,
          title: "Yash Ratnaker",
          text: "Python Developer",
          img: yashImg,
        },
      ],
    },
  ];


  const existanceList = [
    {
      id: 0,
      icon: <Icons.Pen fill="#ff4d00" />,
      text: "Paper grading and personalized feedback generation.",
    },
    {
      id: 1,
      icon: <Icons.Chart fill="#ff4d00" />,
      text: "Analyzing student performance with SWOT-based insights.",
    },
    {
      id: 2,
      icon: <Icons.Network fill="#ff4d00" />,
      text: "Managing large information systems with intelligent AI-driven solutions.",
    },
  ];

  const handleTab = (tabName) => {
    setTab(tabName);
  };

  const handleDirectorModal = (
    img,
    name,
    designation,
    about,
    email,
    linkedin
  ) => {
    setShowDrawer(true);
    setDirectorData({
      img: img,
      name: name,
      designation: designation,
      about: about,
      email: email,
      linkedin: linkedin,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-page">
      <EduHeader />
      <main className="about-page">
        <section className="page-title-section">
          <div className="container">
            <h2 className="page-title">
              Making AI Truly <span>Accessible for Everyone</span>
            </h2>
          </div>
        </section>
        <section className="our-vision">
          <div className="container">
            <div className="vision-container">
              <h3 className="main-title">
                At Chanakya AI, our mission is to make Artificial Intelligence
                accessible to the masses by breaking the barriers of tedious and
                repetitive tasks in education and knowledge systems.{" "}
                <span>
                  We envision a future where AI is no longer a privilege but a
                  tool that empowers educators, students, and professionals{" "}
                </span>{" "}
                to focus on what truly matters—creativity, learning, and growth.
              </h3>
              <div className="side-content-wrapper">
                {visionData?.map((item, index) => (
                  <div className="content-card">
                    <h4 className="title">{item.title}</h4>
                    <p className="text">{item.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="our-history">
          <div className="container">
            <h3 className="section-title">Our History</h3>
            <p className="section-text">Our Journey of Innovation & Growth</p>
            <div className="history-wrapper">
              <div>
                <h4 className="title">
                  Chanakya AI began as a vision to serve India, launching as a
                  chatbot uniquely designed for Indian users. It provided quick
                  access to information and knowledge, catering to the specific
                  needs and cultural nuances of the Indian audience. The
                  overwhelming love and positive response from our users
                  inspired us to dream bigger.
                </h4>
                <p className="text">
                  Seeing the transformative impact AI can have, we set out to
                  revolutionize education. This journey led us to build Chanakya
                  AI Edu, a platform designed to simplify paper grading,
                  performance analysis, and personalized learning for students
                  and teachers. What started as a small step to make knowledge
                  accessible has now evolved into a mission to enhance education
                  systems and empower educators and learners alike.
                </p>
              </div>
              <div className="img-wrapper">
                <img src={historyImg} alt="history" />
              </div>
            </div>
          </div>
        </section>
        <section className="existance">
          <div className="container">
            <h3 className="section-title">Why We Exist</h3>
            <p className="section-text">
              We believe that time is one of the most valuable resources.
              Chanakya AI helps educators, institutions, and knowledge-driven
              organizations reclaim their time by automating processes like:
            </p>
            <div className="existance-card">
              <div className="card-body">
                {existanceList?.map((item, index) => (
                  <div key={index} className="card-item">
                    <div>{item.icon}</div>
                    <p className="card-text">{item.text}</p>
                  </div>
                ))}
              </div>
              <p className="card-text">
                This ensures more focus on high-impact tasks such as
                personalized teaching, concept clearing and creative
                problem-solving.
              </p>
            </div>
          </div>
        </section>
        <section className="our-values">
          <div className="container">
            <h3 className="section-title">Our Values</h3>
            <p className="section-text">
              Our values are part of everything we create at instrument
            </p>
            <div className="vaues-content">
              {/* <div className="values-img">
                <img src={historyImg} alt="sectionImage" />
              </div> */}
              <div className="value-accordion-main">
                <div
                  className="accordion values-accordion"
                  id="accordionExample"
                >
                  {valuesData?.map((item, index) => (
                    <div key={index} className="accordion-item">
                      <h2
                        className="accordion-header collapsed"
                        data-bs-target={`#collapse${item.id}`}
                        aria-controls={`collapse${item.id}`}
                        data-bs-toggle="collapse"
                        id={`heading${item.id}`}
                      >
                        {item?.btnText}
                        <span className="acc-button"></span>
                      </h2>
                      <div
                        id={`collapse${item.id}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading${item.id}`}
                        data-bs-parent="#accordionExample"
                      >
                        <p className="accordian-text">{item.text}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="our-future">
          <div className="container">
            <h3 className="section-title">Our Future</h3>
            <p className="section-text">Driving a Smarter Future</p>
            <div className="future-card">
              <p className="card-text">
                <span> Chanakya AI's</span> goal is to redefine education and
                knowledge systems by bridging the gap between advanced AI
                technologies and everyday users. Whether it's a school teacher
                in a remote village or a global institution managing vast data
                systems, we aim to equip everyone with tools that simplify life
                and amplify productivity.
              </p>
              <p className="card-text center">
                Join us on this journey to make AI a force for good
              </p>
              <p className="card-text center flex">
                <span> Accessible</span> <span> Impactful</span>{" "}
                <span> Empowering for All</span>
              </p>
            </div>
          </div>
        </section>
        <section className="our-team">
          <div className="container">
            <h3 className="section-title">Our Team</h3>
            <p className="section-text">Meet the team behind the vision</p>
            <div className="tabs-wrapper">
              <div className="btn-main">
                <button
                  type="button"
                  className={`tabs-button ${tab === "directors" && "active"}`}
                  onClick={() => handleTab("directors")}
                >
                  Board of Directors
                </button>
                <button
                  type="button"
                  className={`tabs-button ${tab === "team" && "active"}`}
                  onClick={() => handleTab("team")}
                >
                  Core Team
                </button>
              </div>
            </div>
            <div className="tab-content">
              {tab === "directors" && (
                <div className="director-content">
                  {myTeam?.[0]?.listOfDirectors?.map((item, index) => (
                    <div
                      key={index}
                      className="director-card"
                      onClick={() =>
                        handleDirectorModal(
                          item.img,
                          item.name,
                          item.designation,
                          item.about,
                          item.email,
                          item.linkedin
                        )
                      }
                    >
                      <div className="card-header">
                        <div>
                          <h4 className="card-title">{item.name}</h4>
                          <p className="card-text">{item.designation}</p>
                        </div>
                        <Icons.ArrowRight2 size={24} fill="#1f1f1f" />
                      </div>
                      <div className="img-container">
                        <img src={item.img} alt="director" />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {tab === "team" && (
                <div className="team-content">
                  {myTeam?.[1]?.listOfTeam?.map((item, index) => (
                    <div key={index} className="director-card">
                      <div className="card-header">
                        <div>
                          <h4 className="card-title">{item.title}</h4>
                          <p className="card-text">{item.text}</p>
                        </div>
                        <Icons.ArrowRight2 width={26} height={24} />
                      </div>
                      <div className="img-container">
                        <img src={item.img} alt="director" />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="company-details">
          <div className="container">
            <h3 className="section-title">Company Details</h3>
            <p className="company-name">Neurobridge Tech Private Limited</p>
            <p className="company-address">
              IInd FLOOR, 2393/222, Chatta Shahji, Chawri Bazar, Delhi, 110006
            </p>
          </div>
        </section>
      </main>
      <LeftDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        directorData={directorData}
      />
      <OuterFooter />
    </div>
  );
};

export default AboutPage;
